<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑' : '添加分拣员'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="account">
          <el-input
            v-model.trim="form.data.account"
            maxlength="11"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password" v-if="!isChange">
          <el-input
            v-model.trim="form.data.password"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 超级管理员选地区 -->
        <el-form-item label="所属地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            clearable
            filterable
            style="width:100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="分拣类别" prop="cate_ids">
          <el-select
            v-model="selectValue"
            @change="changeSelect"
            multiple
            filterable
            clearable
            placeholder="请选择下单分类"
            style="width:100%"
          >
            <el-option key="selectAll" label="全部" value="selectAll"></el-option>
            <el-option
              v-for="item in cateOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分拣客户" prop="merchant_ids">
          <el-select v-model="form.data.merchant_ids" multiple filterable placeholder="请选择" style="width:100%" >
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'companyContractEdit',
    data() {
      return {
        roleType: getStorage('role_type'),
        login_province: getStorage('province'),
        login_city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            account: '',
            password: '',
            area: '',
            cate_ids: [],
            merchant_ids: [],
          },
          rules: {
            name: [{ required: true, message:'请输入姓名', trigger: 'change'}],
            account: [
              { required: true, message:'请输入电话', trigger: 'change'},
              {
                pattern: REG_EXP.mobilePhone,
                message: '请输入正确的手机号',
                trigger: 'change'
              }
            ],
            password: [{ required: true, message:'请输入密码', trigger: 'change'}],
            area: [{ required: true, message:'请选择所属地区', trigger: 'change'}],
            cate_ids: [{ required: true, message:'请选择分拣类别', trigger: ['blur','change']}],
            merchant_ids: [{ required: true, message:'请选择分拣客户', trigger: 'change'}],
          }
        },
        areaArr: [], // 省市
        cateOpt: [], // 分类
        merchantArr: [], // 客户

        options: [
          {
            value: '选项1',
            label: '黄金糕'
          },
          {
            value: '选项2',
            label: '双皮奶'
          },
          {
            value: '选项3',
            label: '蚵仔煎'
          },
          {
            value: '选项4',
            label: '龙须面'
          },
          {
            value: '选项5',
            label: '北京烤鸭'
          }
        ],
        selectValue: [],
        selectAll: false // 用于标识是否全选--默认不全选
      }
    },
    methods: {
      getDetail(row) {
        this.getArea(); // 省市
        this.getCate(); // 商品分类
        this.getMerchant(); // 客户
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.area = [row.province, row.city]
            this.form.data.cate_ids = row.cate_ids.split("|").map(Number)
            this.selectValue = row.cate_ids.split("|").map(Number);
            this.form.data.merchant_ids = row.merchant_ids.split("|").map(Number)
          })
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data.cate_ids = []
        this.selectValue = []
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      // 获取分类
      getCate() {
        // this.$http.post('/admin/cate/all',{}).then(res => {
        //   if(res.code == 1) {
        //     this.cateOpt = res.data;
        //   }
        // })
        this.$http.get('/admin/mini_cate/list', {params:{page:1,count:100}}).then(res => {
          if(res.code === 1) {
            this.cateOpt = res.data.list;
          }
        })
      },

      // 根据城市获取客户列表
      getMerchant() {
        // type 1:列表 2:参数
        this.$http.get("/admin/group/merchantList", {params:{type:1,page:1,count:10000}}).then((res) => {
          if(res.code == 1) {
            this.merchantArr = res.data.list;
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              account: this.form.data.account,
              password: this.form.data.password,
              province: this.roleType == 1 ? this.form.data.area[0] : this.login_province,
              city: this.roleType == 1 ? this.form.data.area[1] : this.login_city,
              cate_ids: this.form.data.cate_ids.join("|"),
              merchant_ids: this.form.data.merchant_ids.join("|"),
            };
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/sorter/edit' : '/admin/sorter/add'
            // return
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      changeSelect(value) {
        //  selectAll 为true 的时候，就走全选分支，全选后出现的情况就是取消全选
        if (this.selectAll) {
          this.selectAll = false
          if (value.indexOf('selectAll') > -1) {
            this.selectValue = value.filter(p => p != 'selectAll')
          } else {
            this.selectValue = []
          }
        } else {
          //   是否点击了‘全选'选项
          if (value.indexOf('selectAll') > -1) {
            // 有‘全选'选项，则将‘全部'和其他值放置一块
            const optionsValue = []
            this.cateOpt.forEach(item => {
              optionsValue.push(item.id)
            })
            this.selectValue = ['selectAll', ...optionsValue]
            this.selectAll = true
          } else {
            // 若是勾选选择的长度和提供的选项长度是一样的，则是 ‘全选'
            if (value.length === this.cateOpt.length) {
              const optionsValue = []
              this.cateOpt.forEach(item => {
                optionsValue.push(item.id)
              })
              this.selectValue = ['selectAll', ...optionsValue]
              this.selectAll = true
            } else {
              //   都是单选
              this.selectValue = value
            }
          }
        }
        // 真实的勾选值
        const realSelect = this.selectValue.filter(item => item != 'selectAll')
        this.form.data.cate_ids = realSelect;
        console.log('realSelect', this.form.data.cate_ids)
      }

    }
  }
</script>
